import React from 'react'
import { motion } from 'framer-motion'

function Home() {
    return (
        <div className='home'>
            <div className='aboutMore'>
                <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className='logo'>
                    <img src='/images/gitm.webp' alt='Logo'/>
                </motion.div>
                <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className='slogan'>
                    Мэдээллийн урсгалыг автоматжуулан бизнес өсөлтийг хурдасгана
                </motion.div>
                <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className='description'>
                    Компанийн цахим хуудас тун удахгүй
                </motion.div>
                <div className='partners'>
                    <a href='https://datacom.mn/' target='_blank' rel="noreferrer">
                        <div className='singlePartner'>
                            <motion.div initial={{ opacity: 0, x: 40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className='logo'>
                                <img src='/images/datacom.webp' alt='Datacom'/>
                            </motion.div>
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.9 }} viewport={{ once: true }} className='type'>
                                Технологи & Аюулгүй ажиллагааны хамтрагч
                            </motion.div>
                        </div>
                    </a>
                </div>
                <div className='timeline'>
                    <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 1.0 }} viewport={{ once: true }} className='title-cont'>
                        <div className='title'>
                            <div className='amarlogo'>
                                <img src='/images/amarhr.webp' alt='amarhr'/>
                            </div>
                            хувилбар 1.1
                        </div>
                        <span className='date'>/Бэлэн болсон/</span>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 1.1 }} viewport={{ once: true }} className='dashedLine'></motion.div>
                    <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 1.2 }} viewport={{ once: true }} className='title-cont'>
                        <div className='title'>
                            <div className='comingSoon'>
                                <div className='loader' /> 
                            </div>
                            Шийдвэр гаргагчдад зориулсан AMARHR апп
                        </div>
                        <span className='date'>/2025 оны 2-р улиралд/</span>
                    </motion.div>
                    <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 1.3 }} viewport={{ once: true }} className='dashedLine'></motion.div>
                </div>
                <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 1.3 }} viewport={{ once: true }} className='contactUs'>
                    Холбоо барих: <a href="mailto:support@amarhr.mn">support@amarhr.mn</a>
                </motion.div>
            </div>
        </div>
    );
}

export default Home;
